import Head from "next/head";
import Link from "next/link";
import Image from "next/image";
import React from "react";
import {Container} from "@chakra-ui/react";

//@ts-ignore
export const WebsiteLayout = ({children}) => {

  return <Container maxW={"container.xl"} paddingX={[2, 4, 4, 16]}>
    {children}
  </Container>

}
