import {
  Box,
  chakra,
  Container, Image,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import {FaTwitter} from 'react-icons/fa';
import {ReactNode} from 'react';
import Link from "next/link";


const SocialButton = ({
                        children,
                        label,
                        href,
                      }: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue('', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        spacing={4}
        justify={'center'}
        align={'center'}>
        <Stack direction={['column', "row"]} spacing={6}>
          <Stack direction={'row'} justifyContent={"center"} spacing={6}>
            <Link href={'/'}>Home</Link>
            <Link href={'/auth/signin'}>Log In</Link>
            <Link href={'/blog'}>Blog</Link>
          </Stack>
          <Stack direction={'row'} spacing={4}>
            <Link href={'/api-doc'}>API</Link>
            <Link href={'/privacy-policy.html'}>Privacy Policy</Link>
            <Link href={'mailto:notionsender@gmail.com'}>Contact</Link>
          </Stack>
        </Stack>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{base: 'column', md: 'row'}}
          spacing={4}
          justify={{base: 'center', md: 'space-between'}}
          align={{base: 'center', md: 'center'}}>
          <Text>© 2022 Notion Sender.</Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Twitter'} href={'https://twitter.com/NotionSender'}>
              <FaTwitter/>
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
