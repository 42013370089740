import React from "react";
import Head from "next/head";


export type PageHeadProps = {
    title: string
    description?: string
    children: React.ReactNode;
}

export const PageHead = ({title, description, children}: PageHeadProps) => {
  return <>
    <Head>
      <title>{title}</title>
      {description && <meta name="description" content={description}/>}
      <script src="https://app.lemonsqueezy.com/js/lemon.js" defer/>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
      <link rel="icon" href="/birb.svg"/>
      <meta property="og:title" content={title}/>
      <meta property="og:image" content="https://www.notionsender.com/og.png"/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://www.notionsender.com/og.png"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:site" content="@NotionSender"/>
      <meta name="twitter:creator" content="@NotionSender"/>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-M34V0567ES"></script>
      <script dangerouslySetInnerHTML={{
        __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-M34V0567ES');`
      }}/>
    </Head>
    <>
      {children}
    </>
  </>
}


