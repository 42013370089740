import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure, Image,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {URLS} from "../../../constants";
import NextLink from "next/link";
import {motion} from "framer-motion";
import {event} from "../../../services/analytics";
import {useState} from "react";


export default function Navigation() {
  const {isOpen, onToggle} = useDisclosure();

  const [loading, setLoading] = useState(false);

  const contentVariants = {
    hidden: {opacity: 0},
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <Box
      as={motion.div}
      initial="hidden"
      animate="visible"
      variants={contentVariants}
      position="sticky"
      top={0}
      zIndex={999}
      py={{base: 2, md: 4}}
    >
      <Flex
        rounded={"full"}
        shadow={"md"}
        bgColor={"white"}
        border={"0.5px solid"}
        borderColor={"gray.100"}
        color={'gray.600'}
        minH={'50px'}
        py={{base: 2, md: 2}}
        px={{base: 8, md: 8}}
        align={'center'}
      >
        <Flex
          flex={{base: 1, md: 'auto'}}
          ml={{base: -2}}
          display={{base: 'flex', md: 'none'}}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3}/> : <HamburgerIcon w={5} h={5}/>
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{base: 1}} justify={{base: 'center', md: 'start'}}>
          <NextLink href={URLS.LANDING}>
            <Image src={"/birb.svg"} h={12} w={12} alt={"NotionSender Logo"}/>
          </NextLink>

          <Flex display={{base: 'none', md: 'flex'}} ml={10}>
            <DesktopNav/>
          </Flex>
        </Flex>

        <Stack
          flex={{base: 1, md: 0}}
          justify={'flex-end'}
          direction={'row'}
          spacing={6}>
          <Button
            display={{md: "none"}}
            as={'a'}
            fontSize={'sm'}
            fontWeight={400}
            variant={'link'}
            href={URLS.LOGIN}>
            <span>Sign In</span>
          </Button>
          <NextLink href={URLS.LOGIN}>
            <Button
              display={{base: 'none', md: 'inline-flex'}}
              fontSize={'sm'}
              // variant={""}
              isLoading={loading}
              fontWeight={600}
              colorScheme={"blue"}
              size={"sm"}
              onClick={() => {
                event({action: "click_connect_notion_nav", params: {}})
                setLoading(true)
              }}
            >
              <span>Connect Notion</span>
            </Button>
          </NextLink>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav/>
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = 'gray.600';
  const linkHoverColor = 'gray.800';
  const popoverContentBgColor = 'white';

  return (
    <Stack direction={'row'} spacing={4} alignItems={"center"}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? '#'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({label, href, subLabel}: NavItem) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{bg: 'blue.50'}}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{color: 'blue.400'}}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{opacity: '100%', transform: 'translateX(0)'}}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'blue.400'} w={5} h={5} as={ChevronRightIcon}/>
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={'white'}
      border={"1px solid"}
      borderColor={"gray.200"}
      p={4}
      zIndex={10}
      mt={1}
      display={{md: 'none'}}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({label, children, href}: NavItem) => {
  const {isOpen, onToggle} = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={'gray.600'}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{marginTop: '0!important'}}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={'gray.200'}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'Save Emails',
    href: '/#receive',
  },
  {
    label: 'Send Emails',
    href: '/#send',
  },
  {
    label: "How to Start",
    href: '/#how-to'
  },
  {
    label: "Guides",
    href: '/#video-block'
  },
  {
    label: "FAQ",
    href: '/#faq'
  },

  // {
  //   label: 'Create email from Notion pages',
  //   href: "/create"
  // }
];
