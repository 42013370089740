export const customTitle = (title: string) => title.trim() + " | " + "NotionSender"

export const LANDING_TITLE = "Send and receive emails in Notion | NotionSender"
export const LANDING_META =  "Easy to use Notion email integration. Save emails to Notion and send emails from Notion. Integrate Notion, Gmail, Outlook, and Apple Mail."

export const API_DOC_META = "Render notion pages to the HTML email and integrate with the notion. " +
    "Use render API to build an easy templating for your marketing or transactional emails. "

export const CREATE_PAGE_META = "Create email from notion page. Submit notion page url and send email. Customize the final look of the email."



export const NOT_FOUND_TITLE = "Page not found | NotionSender"
export const NOT_FOUND_META = "Page not found | Create emails from notion | NotionSender"




export const URLS = {
  LOGIN: "/auth/signin",
  LANDING: "/",
  APP_HOME:"/app/home",
  CREATE_PAGE: "/create",
  BLOG: "/blog",
  TEMPLATES: "https://nsender.notion.site/NotionSender-Examples-d886267388f74325b8cd756954ab49c7",
  BIG_TEMPLATE: "https://nsender.notion.site/NotionSender-Email-Hub-Template-19d54b738c0d4e96b58b11cd0549dedd?pvs=4"


}


export const PRO_FEATURES_LIST = [
  "Save up to 10,000 emails",
  "Send up to 5000 emails",
  "Property Extraction with AI",
  "Convert emails into Notion blocks",
  "Unlimited email addresses",
  "Save and send attachments",
  "Email previews for Gallery view",
  "Embed for media-rich emails",
  "Email templating and scheduling",
  "Custom sender address"
]
